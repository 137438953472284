import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.scss';
import Header from './components/header/Header';
import Home from './pages/home/Home';
import Footer from './components/footer/Footer';

function App() {
  return (
    <>
      <BrowserRouter>
        <Header />
        <main>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about-us" element={<Home />} />
            <Route path="/products" element={<Home />} />
            <Route path="/application" element={<Home />} />
            <Route path="/our-presence" element={<Home />} />
            <Route path="/inquiry" element={<Home />} />
            <Route path="/contact-us" element={<Home />} />
          </Routes>
        </main>
      </BrowserRouter>
      <Footer />
    </>
  );
}

export default App;
