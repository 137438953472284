import './Footer.scss';

function Footer() {
    return (
        <footer className="text-center py-1">
            Copyright &copy; 2023 All rights reserved | Anagh Ceramic
        </footer>
    );
}

export default Footer;
