import { Link } from 'react-router-dom';
import './Header.scss';
import { useState } from 'react';

function Header() {
    const [show, setShow] = useState(false);
    return (
        <header className="px-4 py-2 position-sticky top-0">
            <nav className="navbar navbar-expand-lg navbar-light py-0">
                <div className="container-fluid">
                    <a href="/">
                        <img src="/assets/images/logo.png" alt="Anagh Ceramic" className="brand" />
                    </a>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown"
                        aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation" onClick={() => setShow(!show)}>
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className={`collapse navbar-collapse justify-content-end ${show ? 'show' : ''}`} id="navbarNavDropdown">
                        <ul className="navbar-nav">
                            <li className="nav-item"><Link className="nav-link" to="/">Home</Link></li>
                            <li className="nav-item"><Link className="nav-link" to="/about-us">About Us</Link></li>
                            <li className="nav-item"><Link className="nav-link" to="/products">Products</Link></li>
                            <li className="nav-item"><Link className="nav-link" to="/application">Application</Link></li>
                            <li className="nav-item"><Link className="nav-link" to="/our-presence">Our Presence</Link></li>
                            <li className="nav-item"><Link className="nav-link" to="/inquiry">Inquiry</Link></li>
                            <li className="nav-item"><Link className="nav-link" to="/contact-us">Contact Us</Link></li>
                        </ul>
                    </div>
                </div>
            </nav>
        </header>
    );
}

export default Header;
