
function ComingSoon() {
  return (
    <div className="text-center">
        <h3 className="pt-5 ">Something big is coming...</h3>
        <img className="img-fluid" src ="/assets/images/coming-soon.png" alt="Coming Soon" />
    </div>
  );
}

export default ComingSoon;
